import React, { useContext, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useAuth } from "./AuthContext";
moment.locale("pt-br");

export const DatePicker = () => {
  const { updateFiltroDatas, filtroDatas } = useAuth();
  const [startDate, setStartDate] = useState(filtroDatas.startDate);
  const [endDate, setEndDate] = useState(filtroDatas.endDate);

  const gastos = [];

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleFilterUpdate = () => {
    // Your filter logic here
    const filteredGastos = gastos.filter(
      (gasto) =>
        moment(gasto.dataConta.toDate()).isSameOrAfter(startDate, 'month') &&
        moment(gasto.dataConta.toDate()).isSameOrBefore(endDate, 'month')
    );

    // Call the context function to update the filter
    updateFiltroDatas(startDate, endDate);
  };

  return (
    <div className="flex gap-4 mt-4">
      <div>
        <ReactDatePicker
          selected={startDate}
          onChange={handleStartDateChange}dateFormat="dd/MM/yyyy" // Define o formato da data
          className="custom-datepicker p-2 rounded-md bg-gray-900 text-white text-center" // Add your custom class
        />
      </div>
      <div>
        <ReactDatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          dateFormat="dd/MM/yyyy"
          className="custom-datepicker p-2 rounded-md bg-gray-900 text-white text-center" // Add your custom class
        />
      </div>
      <button className="bg-gray-900 text-blue-500 rounded-md px-6" onClick={handleFilterUpdate}>Aplicar Datas</button>
    </div>
  );
};
