import React, { useContext, useEffect, useState } from 'react';
import { DadosContext } from '../../common/DadosContext';
import moment from "moment";
import "moment/locale/pt-br";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import pdfjsLib from 'pdfjs-dist';


moment.locale("pt-br");

const NotasComponent = () => {
  const { notas } = useContext(DadosContext);
  const [filtroTipo, setFiltroTipo] = useState('todos');
  const [search, setSearch] = useState('');
  const [rankings, setRankings] = useState([]);

  const defaultPrintParams = {
    // Defina suas opções de impressão aqui, se necessário
  };

  useEffect(() => {
    const calcularRanking = () => {
      const notasPorMarketplace = {};

      // Contar o número de notas por marketplace por mês
      notas.forEach(nota => {
        const mesAno = moment(nota.nota.dataEmissao).format('MMMM YYYY');
        const marketplaceId = nota.nota.loja.id.toString();

        if (!notasPorMarketplace[mesAno]) {
          notasPorMarketplace[mesAno] = {};
        }

        if (!notasPorMarketplace[mesAno][marketplaceId]) {
          notasPorMarketplace[mesAno][marketplaceId] = 0;
        }

        notasPorMarketplace[mesAno][marketplaceId]++;
      });

      // Criar um array de rankings por mês
      const rankingsPorMes = Object.keys(notasPorMarketplace).map(mesAno => {
        const notasPorMarketplaceNoMes = notasPorMarketplace[mesAno];
        const sortedRanking = Object.keys(notasPorMarketplaceNoMes).sort((a, b) => notasPorMarketplaceNoMes[b] - notasPorMarketplaceNoMes[a]);
        return {
          mesAno: mesAno,
          topMarketplaces: sortedRanking.slice(0, 3).map((marketplaceId, index) => ({
            id: marketplaceId,
            nome: getIdLink(marketplaceId),
            quantidadeNotas: notasPorMarketplaceNoMes[marketplaceId],
            posicao: index + 1
          }))
        };
      });

      setRankings(rankingsPorMes);
    };

    calcularRanking();
  }, [notas]);

  // Função para filtrar as notas com base no tipo de marketplace selecionado
  const filtrarNotas = () => {
    switch (filtroTipo) {
      case 'todos':
        return notas;
      default:
        return notas.filter(nota => nota.nota.loja.id.toString() === filtroTipo);
    }
  };

  // Função para obter o nome do marketplace com base no ID
  const getIdLink = (id) => {
    switch (id) {
      case '203601242':
        return 'Mercado Livre';
      case '203526985':
        return 'Amazon';
      case '203344818':
        return 'Magalu';
      case '203309938':
        return 'Leroy';
      case '203651867':
        return 'Tray';
      default:
        return '';
    }
  };

  // Função para gerar os dados do gráfico com base no marketplace selecionado
  const getChartData = (marketplaceId) => {
    // Verificar se o marketplaceId é "todos"
    if (marketplaceId === 'todos') {
      // Retorna todos os dados de notas, independentemente do marketplace
      const data = [];
      const uniqueMonths = Array.from(new Set(notas.map(nota => moment(nota.nota.dataEmissao).format('MMMM YYYY'))));
      uniqueMonths.forEach(monthYear => {
        const notasMonthYear = notas.filter(nota => moment(nota.nota.dataEmissao).format('MMMM YYYY') === monthYear);
        data.push({
          name: monthYear,
          value: notasMonthYear.length
        });
      });
      return data;
    } else {
      // Retorna os dados apenas para o marketplace especificado
      const notasMarketplace = notas.filter(nota => nota.nota.loja.id.toString() === marketplaceId);
      const data = [];
      const uniqueMonths = Array.from(new Set(notasMarketplace.map(nota => moment(nota.nota.dataEmissao).format('MMMM YYYY'))));
      uniqueMonths.forEach(monthYear => {
        const notasMonthYear = notasMarketplace.filter(nota => moment(nota.nota.dataEmissao).format('MMMM YYYY') === monthYear);
        data.push({
          name: monthYear,
          value: notasMonthYear.length
        });
      });
      return data;
    }
  };

  // Renderização do componente
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 min-h-screen py-24">
      <div className="mb-4">
        <div className='flex justify-between'>
          <select
            id="filtroTipo"
            className="appearance-none border rounded py-2 px-3 bg-slate-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline shadow-md"
            value={filtroTipo}
            onChange={(e) => setFiltroTipo(e.target.value)}
          >
            <option value="todos">Todos</option>
            <option value="203526985">Amazon</option>
            <option value="203344818">Magalu</option>
            <option value="203601242">Mercado Livre</option>
          </select>
          <input
            className="appearance-none border rounded py-2 px-3 bg-slate-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline shadow-md"
            onChange={(e) => setSearch(e.target.value)}
            placeholder='Nota ou Nome'
          />
          <input
            className="appearance-none border rounded py-2 px-3 bg-slate-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline shadow-md"
            onChange={(e) => setSearch(e.target.value)}
            placeholder='Série'
          />
        </div>
      </div>
      <div className="my-4">
        <h3 className="text-lg font-semibold mb-2">Gráfico de Notas por Mês</h3>
        {filtroTipo && (
          <ResponsiveContainer width="100%" height={200}>
            <AreaChart data={getChartData(filtroTipo)}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Area type="monotone" dataKey="value" fill="#8884d8" />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
      <div className="mb-4 container items-center justify-center mx-auto">
        <h2 className="text-xl font-semibold mb-4">Ranking por Marketplace</h2>
        <div className="overflow-x-auto">
          <table className="w-full table-auto border-collapse bg-slate-100 shadow-xl">
            <thead>
              <tr className="bg-gray-300">
                <th className="py-2 px-4 text-left">Mês/Ano</th>
                <th className="py-2 px-4 text-left">1º</th>
                <th className="py-2 px-4 text-left">2º</th>
                <th className="py-2 px-4 text-left">3º</th>
              </tr>
            </thead>
            <tbody>
              {rankings.map((ranking, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2 px-4">{ranking.mesAno}</td>
                  {ranking.topMarketplaces.map((marketplace, index) => (
                    <td key={index} className="py-2 px-4">
                      {marketplace.posicao === 1 && <FontAwesomeIcon icon={faTrophy} style={{ color: 'gold' }} />}
                      {marketplace.posicao === 2 && <FontAwesomeIcon icon={faTrophy} style={{ color: 'silver' }} />}
                      {marketplace.posicao === 3 && <FontAwesomeIcon icon={faTrophy} style={{ color: '#cd7f32' }} />}
                      &nbsp;{marketplace.nome} ({marketplace.quantidadeNotas} notas)
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="overflow-x-auto ">
        <table className="w-full table-auto border-collapse bg-slate-100 shadow-xl">
          <thead>
            <tr className="bg-gray-300">
              <th className="py-2 px-4 text-left">Marketplace</th>
              <th className="py-2 px-4 text-left">NF Saída</th>
              <th className="py-2 px-4 text-left">Série</th>
              <th className="py-2 px-4 text-left">Nome</th>
              <th className="py-2 px-4 text-left">Data</th>
              <th className="py-2 px-4 text-left">PDF</th>
            </tr>
          </thead>
          <tbody>
            {filtrarNotas().filter((nota) => {
              return search.trim() === '' ||
                (nota?.nota && nota.nota.numero.toString().toLowerCase().includes(search.toLowerCase()) || nota?.nota && nota.nota.contato.nome.toString().toLowerCase().includes(search.toLowerCase()))
            }).map((nota) => (
              <tr key={nota.id} className="border-b">
                <td className="py-2 px-4">{nota?.nota ? getIdLink(nota.nota.loja.id.toString()) : getIdLink(nota?.ganho.loja.id.toString())}</td>
                <td className="py-2 px-4">{nota?.nota ? nota.nota.numero : nota?.ganho.numero}</td>
                <td className="py-2 px-4">{nota?.nota ? nota.nota.serie : nota?.ganho.serie}</td>
                <td className="py-2 px-4">{nota?.nota ? nota.nota.contato.nome : nota?.ganho.contato.nome}</td>
                <td className="py-2 px-4">{nota?.nota ? moment(nota.nota.dataEmissao).format('DD/MM/YYYY') : moment(nota?.ganho.dataEmissao).format('DD/MM/YYYY')}</td>
                <td className="py-2 px-4">
                  <a
                    href={nota?.nota ? nota.nota.linkPDF : nota?.ganho.linkPDF}
                    className="text-blue-500 hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault(); // Impede o comportamento padrão de abrir o link em uma nova guia

                      // Carrega o PDF usando PDF.js
                      const loadingTask = pdfjsLib.getDocument(
                        nota?.nota ? nota.nota.linkPDF : nota?.ganho.linkPDF
                      );

                      // Quando o PDF for carregado, inicia a impressão
                      loadingTask.promise.then((pdf) => {
                        const printParams = {
                          printable: pdf, // PDF a ser impresso
                          css: 'path_para_seu_arquivo_css', // Caminho para o arquivo CSS a ser aplicado durante a impressão
                          scanStyles: false, // Define se as folhas de estilo da página serão analisadas e aplicadas durante a impressão
                          style: '@page { size: 4in 6in landscape; margin: 2cm }', // Estilo CSS personalizado a ser aplicado durante a impressão
                          onBeforePrint: null, // Função a ser chamada antes de imprimir
                          onAfterPrint: null // Função a ser chamada após a impressão
                        };

                        // Abre o diálogo de impressão quando o PDF for carregado
                        pdfjsLib.getPrintMode().then((printMode) => {
                          if (printMode === pdfjsLib.PDFPrintMode.UNKNOWN) {
                            alert('Não é possível imprimir o PDF');
                          } else {
                            pdfjsLib.getPrintSetup(printParams).then((printParams) => {
                              pdfjsLib.print(pdf, printParams).then((result) => {
                                if (!result) {
                                  alert('Erro ao imprimir o PDF');
                                }
                              }).catch((error) => {
                                alert('Erro ao imprimir o PDF');
                              });
                            });
                          }
                        });
                      }).catch((error) => {
                        console.error('Erro ao carregar o PDF:', error);
                        alert('Erro ao carregar o PDF');
                      });
                    }}
                  >
                    Ver
                  </a>
                </td>


              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NotasComponent;
