import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import VideoThumb from '../Image/hero-image.png';
import ModalVideo from './modal-video';

export const HeroSection = () => {
  // Referências para os círculos
  const circle1Ref = useRef(null);
  const circle2Ref = useRef(null);

  // Configuração do GSAP
  useEffect(() => {
    gsap.fromTo(
      circle1Ref.current,
      { opacity: 0, y: -200 },
      { opacity: 1, y: 0, duration: 5, delay: 0 }
    );
    gsap.fromTo(
      circle2Ref.current,
      { opacity: 0, y: 200 },
      { opacity: 1, y: 0, duration: 5, delay: 0 }
    );

    // Inicializar AOS
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <section className="relative overflow-hidden">
      {/* Ilustração atrás do conteúdo do herói */}
      <div className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none -z-1 w-full" aria-hidden="true">
        <svg width="100%" height="578" viewBox="0 0 1360 578" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle ref={circle1Ref} cx="1232" cy="128" r="128" />
            <circle ref={circle2Ref} cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Conteúdo do herói */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Cabeçalho da seção */}
          <div className="text-center pb-12 md:pb-16">
            <h1 className="text-4xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">
              Impulsione seu negócio <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">digital</span>
            </h1>
            <div className="max-w-3xl mx-auto">
              <p className="text-lg md:text-xl text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="150">
                Nosso serviço de marketing digital é especializado em transformar seu e-commerce em um negócio escalável e lucrativo.
              </p>
              <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">
                <div>
                  <a className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0" href="/login">
                    Começar agora
                  </a>
                </div>
                <div>
                  <a className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4" href="#0">
                    Saber mais
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Imagem do herói */}
          <ModalVideo
            thumb={VideoThumb}
            thumbWidth={768}
            thumbHeight={432}
            thumbAlt="Modal video thumbnail"
            video="/videos/video.mp4"
            videoWidth={1920}
            videoHeight={1080}
          />
        </div>
      </div>
    </section>
  );
}
