import React, { useEffect, useState } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../components/Firebase';

const InventoryNotificationsList = () => {
  const [notifications, setNotifications] = useState([]);
  const [lastNotificationId, setLastNotificationId] = useState(null);
  const [permissionGranted, setPermissionGranted] = useState(Notification.permission === 'granted');

  useEffect(() => {
    // Solicitar permissão para notificações apenas uma vez
    if (!permissionGranted && Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          setPermissionGranted(true);
        }
      });
    }

    const unsubscribe = onSnapshot(
      collection(db, 'webhook/magalu/notifications'),
      (snapshot) => {
        const novasNotificacoes = snapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        const filteredNotifications = novasNotificacoes
          .filter(notification => notification.data.type === 'created_inventory')
          .sort((a, b) => new Date(b.data.created_at) - new Date(a.data.created_at)); // Ordenar por created_at decrescente
        
        console.log("Filtered and sorted notifications:", filteredNotifications); // Log das notificações filtradas e ordenadas

        // Mostrar notificação do navegador para novas mudanças de inventário
        if (permissionGranted && filteredNotifications.length > 0) {
          const latestNotification = filteredNotifications[0]; // Assumindo que o mais recente está na posição 0
          if (latestNotification.id !== lastNotificationId) {
            new Notification('Mudança de Inventário', {
              body: `SKU: ${latestNotification.data.sku}\nQuantidade: ${latestNotification.data.quantity}\nData: ${latestNotification.data.created_at}`,
            });
            setLastNotificationId(latestNotification.id); // Atualiza o ID da última notificação
          }
        }

        setNotifications(filteredNotifications);
      },
      (error) => {
        console.error('Error fetching notifications: ', error);
      }
    );

    return () => unsubscribe();
  }, [lastNotificationId, permissionGranted]);

  if (!notifications.length) {
    return <div>No inventory change notifications found</div>;
  }

  return (
    <div className='max-w-4xl mx-auto px-4 sm:px-6 py-24 min-h-screen'>
      <h2>Inventory Change Notifications</h2>
      <ul>
        {notifications.map((notification) => (
          <li key={notification.id} className='bg-gray-300 flex gap-4 rounded-md p-4 mb-2'>
            <div><strong>SKU:</strong> {notification.data.sku}</div>
            <div><strong>Quantity:</strong> {notification.data.quantity}</div>
            <div><strong>Created At:</strong> {notification.data.created_at}</div>             
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InventoryNotificationsList;
