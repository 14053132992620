import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { DadosContext } from '../common/DadosContext';
import { Container, Grid, Card, CardContent, Typography, CircularProgress, Button, TextField, AppBar, Toolbar, CssBaseline, Box, Paper, Divider } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell, LineChart, Line } from 'recharts';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const CustomXAxis = ({ dataKey = "name", ...props }) => <XAxis dataKey={dataKey} {...props} />;
const CustomYAxis = ({ ...props }) => <YAxis {...props} />;

const formatCurrency = (value) => {
    const numericValue = (parseFloat(value) / 100).toFixed(2);
    return numericValue.replace('.', ',');
};

export const PedidoMagalu = () => {
    const { tokens } = useContext(DadosContext);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState(new Date('2024-07-30'));
    const [endDate, setEndDate] = useState(new Date());
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [maxEntries, setMaxEntries] = useState(50000);

    const fetchUserData = async (newOffset = 0) => {
        const token = tokens.find(token => token.id === 'magalu')?.token;

        if (!token) {
            setError('Token não encontrado');
            setLoading(false);
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await axios.post('https://nerp.com.br/magalu/pedidos', {
                accessToken: token,
                startDate: startDate.toISOString().split('T')[0],
                endDate: endDate.toISOString().split('T')[0],
                offset: newOffset,
                limit: 100 // Ajuste o valor conforme necessário
            });

            const newOrders = response.data;
            if (newOrders.length > 0) {
                setUserData(prevData => [...prevData, ...newOrders].slice(0, maxEntries));
                setOffset(prevOffset => prevOffset + newOrders.length);
                if (newOrders.length < 100) {
                    setHasMore(false);
                }
            } else {
                setHasMore(false);
            }
        } catch (error) {
            setError('Falha ao buscar informações do usuário');
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setUserData([]);
        setOffset(0);
        setHasMore(true);
        fetchUserData(0);
    }, [startDate, endDate, maxEntries]);

    const handleDateChange = (newStartDate, newEndDate) => {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const filterByDate = (orders, startDate, endDate) => {
        return orders.filter(order => {
            const createdDate = new Date(order.created_at);
            return createdDate >= startDate && createdDate <= endDate;
        });
    };

    const filteredData = filterByDate(userData, startDate, endDate);

    const calculateKPIs = (data) => {
        const uniqueCustomers = data.reduce((acc, order) => {
            const buyerId = order.agreement.buyer ? order.agreement.buyer.id : null;
            if (buyerId) {
                acc.add(buyerId);
            }
            return acc;
        }, new Set()).size;

        const totalItemsSold = data.reduce((acc, order) => {
            return acc + order.agreement.items.reduce((itemAcc, item) => itemAcc + parseFloat(item.quantity), 0);
        }, 0);

        const totalRevenue = data.reduce((acc, order) => acc + (order.agreement.total.total || 0), 0);

        const averageItemValue = (totalRevenue / totalItemsSold).toFixed(2);
        const averageOrderValue = (totalRevenue / data.length).toFixed(2);

        return {
            totalOrders: data.length,
            totalRevenue: formatCurrency(totalRevenue),
            averageOrderValue: formatCurrency(averageOrderValue),
            uniqueCustomers,
            totalItemsSold,
            averageItemValue: formatCurrency(averageItemValue)
        };
    };

    const kpis = calculateKPIs(filteredData);

    const bestSellingProduct = filteredData.length > 0 ? filteredData.reduce((acc, order) => {
        order.agreement.items.forEach(item => {
            const sku = item.sku.code;
            acc[sku] = acc[sku] || { quantity: 0, name: item.sku.name, total: 0 };
            acc[sku].quantity += parseFloat(item.quantity);
            acc[sku].total += parseFloat(item.total);
        });
        return acc;
    }, {}) : {};

    const sortedProductsByQuantity = Object.keys(bestSellingProduct).sort((a, b) => bestSellingProduct[b].quantity - bestSellingProduct[a].quantity);
    const sortedProductsByRevenue = Object.keys(bestSellingProduct).sort((a, b) => bestSellingProduct[b].total - bestSellingProduct[a].total);

    const top10Products = sortedProductsByQuantity.slice(0, 50).map(sku => ({
        ...bestSellingProduct[sku],
        sku,
        percentageRevenue: ((bestSellingProduct[sku].total / filteredData.reduce((acc, order) => acc + parseFloat(order.agreement.total.total), 0)) * 100).toFixed(2),
    }));

    const bottom10Products = sortedProductsByQuantity.slice(-50).map(sku => ({
        ...bestSellingProduct[sku],
        sku,
        percentageRevenue: ((bestSellingProduct[sku].total / filteredData.reduce((acc, order) => acc + parseFloat(order.agreement.total.total), 0)) * 100).toFixed(2),
    }));

    const top5ProductsByRevenue = sortedProductsByRevenue.slice(0, 5).map(sku => ({
        ...bestSellingProduct[sku],
        sku,
    }));

    const data = filteredData.length > 0 ? filteredData.map(order => ({
        name: order.code,
        total: order.agreement.total.total ? parseFloat(order.agreement.total.total) / 100 : 0,
        items: order.agreement.items.length,
        date: new Date(order.created_at).toLocaleDateString()
    })) : [];

    const revenueOverTime = filteredData.reduce((acc, order) => {
        const date = new Date(order.created_at).toLocaleDateString();
        if (!acc[date]) {
            acc[date] = 0;
        }
        acc[date] += parseFloat(order.agreement.total.total) / 100;
        return acc;
    }, {});

    const revenueOverTimeData = Object.keys(revenueOverTime).map(date => ({
        date,
        revenue: revenueOverTime[date],
    }));

    const itemsSoldByProduct = filteredData.length > 0 ? filteredData.reduce((acc, order) => {
        order.agreement.items.forEach(item => {
            const sku = item.sku.code;
            acc[sku] = acc[sku] || { name: item.sku.name, quantity: 0 };
            acc[sku].quantity += parseFloat(item.quantity);
        });
        return acc;
    }, {}) : {};

    const itemsSoldByProductData = Object.keys(itemsSoldByProduct).map(key => ({
        name: itemsSoldByProduct[key].name,
        quantity: itemsSoldByProduct[key].quantity
    }));

    const paymentData = filteredData.length > 0 ? filteredData.reduce((acc, order) => {
        if (order.agreement.payments) {
            order.agreement.payments.forEach(payment => {
                const method = payment.method;
                acc[method] = (acc[method] || 0) + payment.value;
            });
        }
        return acc;
    }, {}) : {};

    const paymentPieData = Object.keys(paymentData).map(key => ({
        name: key,
        value: paymentData[key],
    }));

    return (
        <>
            <CssBaseline />
            <AppBar position="static" sx={{ backgroundColor: '#4A90E2', height: '80px' }}>
                <Toolbar>
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
                        Dashboard Magalu
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                {loading && offset === 0 ? (
                    <Grid container justifyContent="center">
                        <CircularProgress />
                    </Grid>
                ) : error ? (
                    <Grid container justifyContent="center">
                        <Typography color="error">{error}</Typography>
                        <Button variant="contained" onClick={() => fetchUserData(offset)}>Tentar Novamente</Button>
                    </Grid>
                ) : (
                    <>
                        <Grid container spacing={3} alignItems="center" justifyContent="center">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        label="Data Inicial"
                                        value={startDate}
                                        onChange={(newValue) => handleDateChange(newValue, endDate)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <DatePicker
                                        label="Data Final"
                                        value={endDate}
                                        onChange={(newValue) => handleDateChange(startDate, newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </Grid>
                            </LocalizationProvider>
                        </Grid>
                        <Grid container spacing={3} sx={{ mt: 3 }}>
                            {kpis && Object.keys(kpis).length > 0 && (
                                <>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Card sx={{ border: '1px solid #ddd', boxShadow: 3, textAlign: 'center' }}>
                                            <CardContent>
                                                <Typography variant="h6" color="textSecondary">Total de Pedidos</Typography>
                                                <Typography variant="h4" color="primary">{kpis.totalOrders}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Card sx={{ border: '1px solid #ddd', boxShadow: 3, textAlign: 'center' }}>
                                            <CardContent>
                                                <Typography variant="h6" color="textSecondary">Receita Total</Typography>
                                                <Typography variant="h4" color="primary">{kpis.totalRevenue}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Card sx={{ border: '1px solid #ddd', boxShadow: 3, textAlign: 'center' }}>
                                            <CardContent>
                                                <Typography variant="h6" color="textSecondary">Valor Médio do Pedido</Typography>
                                                <Typography variant="h4" color="primary">{kpis.averageOrderValue}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Card sx={{ border: '1px solid #ddd', boxShadow: 3, textAlign: 'center' }}>
                                            <CardContent>
                                                <Typography variant="h6" color="textSecondary">Número de Clientes Únicos</Typography>
                                                <Typography variant="h4" color="primary">{kpis.uniqueCustomers}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Card sx={{ border: '1px solid #ddd', boxShadow: 3, textAlign: 'center' }}>
                                            <CardContent>
                                                <Typography variant="h6" color="textSecondary">Total de Itens Vendidos</Typography>
                                                <Typography variant="h4" color="primary">{kpis.totalItemsSold}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <Card sx={{ border: '1px solid #ddd', boxShadow: 3, textAlign: 'center' }}>
                                            <CardContent>
                                                <Typography variant="h6" color="textSecondary">Valor Médio por Item</Typography>
                                                <Typography variant="h4" color="primary">{kpis.averageItemValue}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid container spacing={3} sx={{ mt: 3 }}>
                            {top10Products.length > 0 && (
                                <Grid item xs={12} md={6}>
                                    <Card sx={{ border: '1px solid #ddd', boxShadow: 3 }}>
                                        <CardContent>
                                            <Typography variant="h6" color="textSecondary">Top 10 Produtos Mais Vendidos</Typography>
                                            <BarChart width={500} height={300} data={top10Products}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="sku" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="quantity" fill="#82ca9d" />
                                                <Bar dataKey="percentageRevenue" fill="#8884d8" />
                                            </BarChart>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                            {bottom10Products.length > 0 && (
                                <Grid item xs={12} md={6}>
                                    <Card sx={{ border: '1px solid #ddd', boxShadow: 3 }}>
                                        <CardContent>
                                            <Typography variant="h6" color="textSecondary">Top 10 Produtos Menos Vendidos</Typography>
                                            <BarChart width={500} height={300} data={bottom10Products}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="sku" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="quantity" fill="#FF8042" />
                                                <Bar dataKey="percentageRevenue" fill="#8884d8" />
                                            </BarChart>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={3} sx={{ mt: 3 }}>
                            {top5ProductsByRevenue.length > 0 && (
                                <Grid item xs={12} md={6}>
                                    <Card sx={{ border: '1px solid #ddd', boxShadow: 3 }}>
                                        <CardContent>
                                            <Typography variant="h6" color="textSecondary">Top 5 Produtos com Maior Receita</Typography>
                                            <BarChart width={500} height={300} data={top5ProductsByRevenue}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="sku" />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="total" fill="#8884d8" />
                                            </BarChart>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                        <Grid container spacing={3} sx={{ mt: 3 }}>
                            <Grid item xs={12} md={6}>
                                <Card sx={{ border: '1px solid #ddd', boxShadow: 3 }}>
                                    <CardContent>
                                        <Typography variant="h6" color="textSecondary">Totais de Pedidos</Typography>
                                        <BarChart width={500} height={300} data={data}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <CustomXAxis />
                                            <CustomYAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="total" fill="#8884d8" />
                                            <Bar dataKey="items" fill="#82ca9d" />
                                        </BarChart>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card sx={{ border: '1px solid #ddd', boxShadow: 3 }}>
                                    <CardContent>
                                        <Typography variant="h6" color="textSecondary">Pagamentos</Typography>
                                        {paymentPieData.length > 0 ? (
                                            <PieChart width={500} height={300}>
                                                <Pie
                                                    data={paymentPieData}
                                                    cx={250}
                                                    cy={150}
                                                    labelLine={false}
                                                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                                                    outerRadius={120}
                                                    fill="#8884d8"
                                                    dataKey="value"
                                                >
                                                    {paymentPieData.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                                    ))}
                                                </Pie>
                                                <Tooltip />
                                            </PieChart>
                                        ) : (
                                            <Typography variant="body1">Nenhum dado de pagamento disponível</Typography>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mt: 3 }}>
                            <Grid item xs={12} md={6}>
                                <Card sx={{ border: '1px solid #ddd', boxShadow: 3 }}>
                                    <CardContent>
                                        <Typography variant="h6" color="textSecondary">Receita ao Longo do Tempo</Typography>
                                        <LineChart width={500} height={300} data={revenueOverTimeData}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="revenue" stroke="#8884d8" />
                                        </LineChart>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card sx={{ border: '1px solid #ddd', boxShadow: 3 }}>
                                    <CardContent>
                                        <Typography variant="h6" color="textSecondary">Itens Vendidos por Produto</Typography>
                                        <BarChart width={500} height={300} data={itemsSoldByProductData}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Bar dataKey="quantity" fill="#82ca9d" />
                                        </BarChart>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mt: 3 }}>
                            <Grid item xs={12}>
                                <Paper elevation={3} sx={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <Typography variant="h6" color="textSecondary" sx={{ p: 2 }}>Lista de Pedidos</Typography>
                                    <Divider />
                                    {filteredData.map(order => (
                                        <Box key={order.id} sx={{ p: 2, borderBottom: '1px solid #ddd' }}>
                                            <Typography variant="subtitle1" gutterBottom>Código do Pedido: {order.code}</Typography>
                                            <Typography variant="body2">Data da Compra: {new Date(order.purchased_at).toLocaleString()}</Typography>
                                            <Typography variant="body2">Total: BRL {formatCurrency(order.agreement.total.total)}</Typography>
                                            <div>
                                                <Typography variant="body2">Itens:</Typography>
                                                <ul>
                                                    {order.agreement.items.map(item => (
                                                        <li key={item.id}>
                                                            <Typography variant="body2">SKU: {item.sku.code}</Typography>
                                                            <Typography variant="body2">Quantidade: {item.quantity}</Typography>
                                                            <Typography variant="body2">Preço: BRL {formatCurrency(item.sku.price)}</Typography>
                                                            <Typography variant="body2">Total: BRL {formatCurrency(item.total)}</Typography>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {order.agreement.payments && order.agreement.payments.length > 0 && (
                                                <div>
                                                    <Typography variant="body2">Pagamentos:</Typography>
                                                    <ul>
                                                        {order.agreement.payments.map(payment => (
                                                            <li key={payment.id}>
                                                                <Typography variant="body2">Método: {payment.method}</Typography>
                                                                <Typography variant="body2">Valor: BRL {formatCurrency(payment.value)}</Typography>
                                                                <Typography variant="body2">Parcelas:</Typography>
                                                                <ul>
                                                                    {payment.installments.map((installment, index) => (
                                                                        <li key={index}>
                                                                            <Typography variant="body2">Data: {new Date(installment.date).toLocaleDateString()}</Typography>
                                                                            <Typography variant="body2">Valor: BRL {formatCurrency(installment.value)}</Typography>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                            {order.agreement.deliveries && order.agreement.deliveries.length > 0 && (
                                                <div>
                                                    <Typography variant="body2">Entregas:</Typography>
                                                    <ul>
                                                        {order.agreement.deliveries.map(delivery => (
                                                            <li key={delivery.id}>
                                                                <Typography variant="body2">Quantidade: {delivery.quantity}</Typography>
                                                                <Typography variant="body2">Total: BRL {formatCurrency(delivery.total)}</Typography>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </Box>
                                    ))}
                                </Paper>
                            </Grid>
                        </Grid>
                        {hasMore && (
                            <Grid container justifyContent="center" sx={{ mt: 3 }}>
                                <Button variant="contained" onClick={() => fetchUserData(offset)}>Carregar Mais</Button>
                            </Grid>
                        )}
                        {loading && offset !== 0 && (
                            <Grid container justifyContent="center" sx={{ mt: 3 }}>
                                <CircularProgress />
                            </Grid>
                        )}
                    </>
                )}
            </Container>
        </>
    );
};

