import PedidosAmericanas from "../Americanas/Pedidos"
import ProdutoAmericanas from "../Americanas/Produto"
import NotificationsList from "./Notificacao"
import PrecoMagalu from "./Preco"

export const ChatMagalu = () => {
    return(
        <div className="max-w-4xl mx-auto px-4 sm:px-6 py-24 flex">
            {/* <NotificationsList/> */}
            {/* <PrecoMagalu/> */}
           
        </div>
    )

}