import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MoonLoader } from 'react-spinners';
import CriarDevolucao from './CriarDevolucao';
import { DadosContext } from '../common/DadosContext';

const BuscarNota = () => {
  const { tokens } = useContext(DadosContext);
  const [products, setProducts] = useState();
  const [idNota, setIdNota] = useState('19002191214');
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [responseD, setResponseD] = useState({});
  const navigate = useNavigate();

  const fetchProducts = async () => {
    const token = tokens.find(token => token.id === 'bling')?.token;
    try {
      setLoading(true);
      setLoading2(true);

      // Enviar o token e o idNota ao backend
      const response = await axios.post('/bling/nfe', {
        idNota,
        token,
      });

      const responseData = response.data;
      console.log(responseData);
      setProducts(responseData);

    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
    } finally {
      setLoading(false);
      setLoading2(false);
    }
  };

  useEffect(() => {
    if (window.performance && window.performance.navigation.type === 1) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <div className="items-center justify-center min-h-screen py-24">
      <div className='mb-4 text-center w-full'>
        <div className='flex justify-center items-center w-full'>
          <div className='flex flex-col items-center bg-gray-900 rounded-md mt-4 p-8'>
            <h1 className="text-xl font-semibold mb-4 text-white">Nota Fiscal</h1>
            <div className=''>
              <input
                className='text-center bg-yellow-200 text-black rounded-md p-2 focus:outline-none'
                type="text"
                value={idNota}
                onChange={(e) => setIdNota(e.target.value)}
                placeholder="Enter idNota"
              />
              <button
                className='ml-4 bg-green-500 text-white rounded-md p-2 focus:outline-none'
                onClick={fetchProducts}
                disabled={loading || !idNota.trim()}
              >
                {loading ? `Buscando...` : `Buscar Nota`}
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          {loading2 ? (
            <div className="flex flex-col items-center mt-64">
              <MoonLoader size={100} color={'#4CAF50'} loading={loading2} />
              <p className="mt-4 text-gray-300 text-sm">BUSQUE UMA NOTA...</p>
            </div>
          ) : (
            <div className=''>
              <CriarDevolucao products={products} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BuscarNota;
