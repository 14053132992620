import moment from 'moment';
import 'moment/locale/pt-br';
const InfoNota = ({ products }) => {
    function openPdfAndPrint() {
        // Abrir uma nova janela com o link do PDF
        const newWindow = window.open(products.linkDanfe, '_blank');

        // Verificar se a nova janela foi aberta corretamente
        if (newWindow) {
            // Adicionar um evento 'load' à nova janela para imprimir o PDF
            newWindow.onload = () => {
                newWindow.print();
            };
        } else {
            // Caso a nova janela não tenha sido aberta, exibir um aviso
            console.log('Não foi possível abrir a nova janela.');
        }
    }
    return (
        <div className=''>
            <div className='flex flex-col bg-slate-100 p-8 text-black shadow-md'>
                <div className='flex justify-between mb-4'>
                    <h1 className="text-xl font-semibold mb-4">Documento Referenciado</h1>
                    <button className='text-center p-2 border border-red-900  rounded-md' onClick={openPdfAndPrint}>
                        Ver PDF
                    </button>
                </div>

                <div className='flex flex-col  gap-2'>
                    <div className="flex-1">
                        <h1>Chave de Acesso</h1>
                        <input
                            disabled={true}
                            className="text-center appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={products.chaveAcesso}>
                        </input>
                    </div>
                    <div className="flex-1">
                        <h1>Data da Emissao</h1>
                        <input
                            disabled={true}
                            className="text-center appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={moment(products.dataEmissao).format('DD/MM/YYYY')}>

                        </input>
                    </div>
                    <div className="flex-1">
                        <h1>Numero da Nota</h1>
                        <input disabled={true} className="text-center appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={products.numero}></input>
                    </div>
                    <div className="flex-1">
                        <h1>Serie da Nota</h1>
                        <input disabled={true} className="text-center appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={products.serie}></input>
                    </div>
                    <div className="flex-1">
                        <h1>CPF</h1>
                        <input disabled={true} className="text-center appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={products.contato.numeroDocumento}></input>
                    </div>
                </div>
            </div>


        </div>
    )
}
export default InfoNota