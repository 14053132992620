import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { DadosContext } from '../common/DadosContext';

export const SimuladorFrete = () => {
    const { tokens } = useContext(DadosContext);
    const [cepori, setCepori] = useState('02551060');
    const [cepsDes, setCepsDes] = useState([
        { estado: 'SP', cep: '01531001', porcentagem: 38.06 },
        { estado: 'PR', cep: '80410200', porcentagem: 8.65 },
        { estado: 'MG', cep: '30110008', porcentagem: 9.69 },
        { estado: 'RJ', cep: '22290120', porcentagem: 7.27 },
        { estado: 'RS', cep: '95689001', porcentagem: 6.23 },
        { estado: 'PE', cep: '50010000', porcentagem: 5.54 },
        { estado: 'SC', cep: '88010000', porcentagem: 5.54 },
    ]);
    const [peso, setPeso] = useState('15');
    const [vldeclarado, setVldeclarado] = useState('500');
    const [cnpj, setCnpj] = useState('96280192000132');
    const [error, setError] = useState('');
    const [results, setResults] = useState([]);
    const [mediaFrete, setMediaFrete] = useState(null);

    const token = tokens.find(token => token.id === 'jadlog')?.token;
    if (!token) {
        setError('Token não encontrado');
        return;
    }

    const handleFinalSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setResults([]);
        setMediaFrete(null);

        try {
            const formattedResults = [];
            for (const { estado, cep, porcentagem } of cepsDes) {
                try {
                    const response = await axios.post('https://nerp.com.br/jadlog/simulador/frete', {
                        token,
                        cepori,
                        cepdes: cep,
                        peso,
                        vldeclarado,
                        cnpj
                    });

                    if (response.data.frete && response.data.frete[0].erro) {
                        formattedResults.push({ estado, cep, porcentagem, error: response.data.frete[0].erro.descricao });
                    } else {
                        const { vltotal, prazo } = response.data.frete[0];
                        formattedResults.push({ estado, cep, porcentagem, vltotal, prazo });
                    }
                } catch (err) {
                    formattedResults.push({ estado, cep, porcentagem, error: err.response ? err.response.data : err.message || 'Erro ao conectar com o servidor' });
                }
            }
            setResults(formattedResults);
            calcularMediaFrete(formattedResults);
        } catch (err) {
            setError(err.message || 'Erro ao conectar com o servidor');
        }
    };

    const calcularMediaFrete = (results) => {
        const totalPesos = results.reduce((acc, cur) => acc + cur.porcentagem, 0);
        const media = results.reduce((acc, cur) => acc + (cur.vltotal * cur.porcentagem), 0) / totalPesos;
        setMediaFrete(media);
    };

    return (
        <div className="max-w-4xl mx-auto px-4 sm:px-6 py-24 min-h-screen">
            <div className="bg-white shadow-md rounded-lg p-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-6">Criação de Cotação de Frete</h2>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <form onSubmit={handleFinalSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">CEP Origem:</label>
                        <input 
                            type="text" 
                            value={cepori} 
                            onChange={(e) => setCepori(e.target.value)} 
                            required 
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">CEPs Destino (estado:cep:porcentagem):</label>
                        <input 
                            type="text" 
                            value={cepsDes.map(({ estado, cep, porcentagem }) => `${estado}:${cep}:${porcentagem}`).join(', ')} 
                            onChange={(e) => setCepsDes(e.target.value.split(',').map(item => {
                                const [estado, cep, porcentagem] = item.split(':').map(str => str.trim());
                                return { estado, cep, porcentagem: parseFloat(porcentagem) };
                            }))} 
                            required 
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Peso (KG):</label>
                        <input 
                            type="number" 
                            step="0.01" 
                            value={peso} 
                            onChange={(e) => setPeso(e.target.value)} 
                            required 
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Valor Declarado:</label>
                        <input 
                            type="number" 
                            step="0.01" 
                            value={vldeclarado} 
                            onChange={(e) => setVldeclarado(e.target.value)} 
                            required 
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">CNPJ:</label>
                        <input 
                            type="text" 
                            value={cnpj} 
                            onChange={(e) => setCnpj(e.target.value)} 
                            required 
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                        />
                    </div>
                    <button 
                        type="submit" 
                        className="w-full bg-indigo-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Criar Cotação
                    </button>
                </form>
                {results.length > 0 && (
                    <div className="mt-6 bg-gray-100 p-4 rounded-md">
                        <h3 className="text-lg font-medium text-gray-800 mb-2">Resultados das Cotações</h3>
                        {results.map((result, index) => (
                            <div key={index} className="mb-4">
                                <h4 className="text-md font-medium text-gray-700">{result.estado}: {result.cep}</h4>
                                {result.error ? (
                                    <p className="text-red-500">{result.error}</p>
                                ) : (
                                    <pre className="bg-gray-200 p-4 rounded-md">
                                        {`vltotal: ${result.vltotal}, prazo: ${result.prazo}`}
                                    </pre>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                {mediaFrete !== null && (
                    <div className="mt-6 bg-green-100 p-4 rounded-md">
                        <h3 className="text-lg font-medium text-green-800 mb-2">Média Ponderada do Frete</h3>
                        <pre className="bg-green-200 p-4 rounded-md">
                            {`Média Ponderada do Frete: ${mediaFrete.toFixed(2)}`}
                        </pre>
                    </div>
                )}
            </div>
        </div>
    );
};
