import { useState, createContext, useEffect } from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { app } from "../components/Firebase";
import { Navigate } from "react-router-dom";

const provider = new GoogleAuthProvider();
export const AuthGoogleContext = createContext({});

export const AuthGoogleProvider = ({ children }) => {
  const auth = getAuth(app);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
      const loadStorageData = () => {
        const storageUser = sessionStorage.getItem("@AuthFirebase:user");
        const storageToken = sessionStorage.getItem("@AuthFirebase:token");
        if (storageToken && storageUser) {
          const parsedUser = JSON.parse(storageUser);
          setUser(parsedUser);
        }
        setLoading(false);
      };
      loadStorageData();
    
  }, []);

  console.log("Current user:", user);

  function signInGoogle() {
    signInWithPopup(auth, provider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        setUser(user);
        sessionStorage.setItem("@AuthFirebase:token", token);
        sessionStorage.setItem("@AuthFirebase:user", JSON.stringify(user));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Google sign-in error:", errorCode, errorMessage);
      });
  }

  function signInWithEmail(email, password) {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setUser(user);
        user.getIdToken().then((token) => {
          sessionStorage.setItem("@AuthFirebase:token", token);
          sessionStorage.setItem("@AuthFirebase:user", JSON.stringify(user));
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Email sign-in error:", errorCode, errorMessage);
      });
  }

  function signOutUser() {
    signOut(auth)
      .then(() => {
        console.log("User signed out");
        sessionStorage.clear();
        setUser(null);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error("Sign-out error:", errorCode, errorMessage);
      });
  }

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while loading
  }

  return (
    <AuthGoogleContext.Provider
      value={{
        signed: !!user,
        user,
        signInGoogle,
        signInWithEmail,
        signOut: signOutUser,
      }}
    >
      {children}
    </AuthGoogleContext.Provider>
  );
};
