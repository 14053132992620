import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { ClockLoader } from 'react-spinners';
import { useAuth } from '../common/AuthContext';
import moment, { now } from 'moment';
import 'moment/locale/pt-br';
import InfoNota from './InfoNota';
import { DadosContext } from '../common/DadosContext';

const CriarDevolucao = ({ products }) => {


  const { createNota, tokens } = useContext(DadosContext)

  const accessToken = tokens.find(token => token.id === 'bling')?.token;
  const [newDescricaoCurta, setNewDescricaoCurta] = useState('oi');
  const [loading, setloading] = useState(false)
  const [loadingmsg, setloadingmsg] = useState()

  const [Nfenviada, setNfenviada] = useState()

  const [sku, setSku] = useState('')
  const [qtd, setqtd] = useState('1')
  const [valorVenda, setValorVenda] = useState()
  const [retornonfe, setretornonfe] = useState('')
  const [motivoSelecionado, setMotivoSelecionado] = useState('Se arrependeu da compra');
  const [motivoProduto, setMotivoProduto] = useState('Reembolsado / Produto Devolvido');
  const [documentoReferenciadoText, setDocumentoReferenciadoText] = useState('');

  const token = tokens.find(token => token.id === 'bling')?.token;
  useEffect(() => {
    if (!products) {
      return;
    }
    const { numero, serie, dataEmissao } = products;

    const values = [
      `Nota Fiscal: ${numero} - Serie: ${serie}`,
      `Data: ${moment(dataEmissao).format('DD/MM/YYYY HH:mm:ss')}`,
      `Motivo: ${motivoSelecionado} (${motivoProduto})`,
    ];

    const filteredValues = values.filter((value) => value !== undefined && value !== null);

    setDocumentoReferenciadoText(filteredValues.join('\n'));

  }, [products, motivoSelecionado, motivoProduto]);

  useEffect(() => {
    if (!products) {
      return;
    }

    getProdutoNota()

  }, [products]);

  const handleMotivoChange = (event) => {
    setMotivoSelecionado(event.target.value);
  };

  const handleMotivoProduto = (event) => {
    setMotivoProduto(event.target.value);
  };

  const fetchProducts = async (id) => {
    try {
      setloading(true)
      setloadingmsg('Enviada')
      const response = await axios.get(`https://www.bling.com.br/Api/v3/nfe/${id}`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      const responseData = response.data.data.linkDanfe;
      const newWindow = window.open(responseData, '_blank');
      if (newWindow) {
        newWindow.onload = () => {
          // Adicionando um pequeno atraso antes de chamar o print
          setTimeout(() => {
            newWindow.print();
          }, 3000); // 500ms de atraso, ajuste conforme necessário
        };
      } else {
        console.error('Não foi possível abrir a nova janela.');
      }
      imprimirNF()


    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
      fetchProducts()
    } finally {
      setloadingmsg('Tudo Feito, Busque Outra nota')

    }
  };

  const nfeRetorno = async (id) => {
    try {
      setloading(true);
      setloadingmsg('Enviando SEFAZ');

      const response = await axios.post(
        `bling/api/nfe/${id}/send`,
        { token: accessToken }
      );
      console.log(response.data);
    } catch (error) {
      console.log(error);
      nfeRetorno(id);
    } finally {
      fetchProducts(id);
      setloading(false);
    }
  };

  const updateDescricaoCurta = async () => {
    if (!products.contato) {
      console.error('A propriedade "contato" não está presente nos dados do produto.');
      return;
    }
    const data = {
      tipo: 0,
      numero: '',
      dataOperacao: moment().format('YYYY-MM-DD HH:mm:ss'),
      contato: {
        nome: products.contato.nome,
        tipoPessoa: products.contato.tipoPessoa || 'F',
        numeroDocumento: products.contato.numeroDocumento,
        ie: products.contato.ie || null,
        rg: products.contato.rg || null,
        contribuinte: products.contato.contribuinte || 9,
        telefone: products.contato.telefone || '',
        email: products.contato.email || '',
        endereco: {
          endereco: products.contato.endereco.endereco,
          numero: products.contato.endereco.numero,
          complemento: products.contato.endereco.complemento || '',
          bairro: products.contato.endereco.bairro,
          cep: products.contato.endereco.cep,
          municipio: products.contato.endereco.municipio,
          uf: products.contato.endereco.uf,
          pais: '',
        },
      },
      naturezaOperacao: {
        id: 15103033508,
      },
      loja: {
        id: products.loja.id,
      },
      finalidade: 4,
      observacoes: documentoReferenciadoText,
      documentoReferenciado: {
        modelo: '55',
        data: moment(products.dataEmissao).format('YYYY-MM-DD HH:mm:ss'),
        numero: products.numero,
        serie: products.serie,
        contadorOrdemOperacao: '1',
        chaveAcesso: products.chaveAcesso,
      },
      itens: [
        {
          codigo: sku,
          unidade: "UN",
          quantidade: qtd,
          valor: valorVenda,
        },
      ],
    };

    try {
      setloading(true);
      setloadingmsg('Nota de Devolução criada')

      const response = await axios.post(`bling/api/nfe/create`,
        { data, token: accessToken }
      );
      createNota(products)
      console.log('Response data:', response.data.data);

      setNfenviada(response.data.data)
      setNewDescricaoCurta('');
      setloading(false);
      nfeRetorno(response.data.data.id)


      // nfeRetorno(response.data.data.id)
    } catch (error) {
      console.error('Erro ao atualizar descrição curta:', error);

      if (error.response) {
        console.error('Resposta detalhada do servidor:', error.response.data);
        console.error('Código de status HTTP:', error.response.status);

        // Adicione um bloco para imprimir informações detalhadas sobre os campos com problemas
        if (error.response.data && error.response.data.fields) {
          console.error('Campos com problemas:', error.response.data.fields);
        }
      }

      if (error.response && error.response.status === 401) {
        // Tratar erro de autenticação, renovar token ou redirecionar para login
        // Exemplo: navigate('/login');
      }

    } finally {

    }

  };

  function imprimirNF() {
    const responseNF = products.linkDanfe;
    const newWindow = window.open(responseNF, '_blank');

    if (newWindow) {
      newWindow.onload = () => {
        // Adicionando um pequeno atraso antes de chamar o print
        setTimeout(() => {
          newWindow.print();
        }, 3000); // 500ms de atraso, ajuste conforme necessário
      };
    } else {
      console.error('Não foi possível abrir a nova janela.');
    }
  }


  const [produtoNota, setProdutonota] = useState()

  const getProdutoNota = async () => {
    if (!products || !products.contato || !products.loja) {
      console.error('Erro: Dados do contato ou da loja estão ausentes');
      return;
    }

    setloading(true);
    try {
      const response = await axios.post('https://nerp.com.br/bling/getProdutoNota', {
        params: {
          accessToken: token,
          contatoId: products.contato.id,
          lojaId: products.loja.id,
        },
      });

      const data = response.data;
      console.log(data);
      setProdutonota(data.produtoNota);
      setValorVenda(data.valorVenda);
      setSku(data.sku);
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
    } finally {
      setloading(false);
    }
  };

  if (loading) {
    // Render the loading spinner while data is being fetched
    return (
      <div className="flex items-center justify-center">
        <div className='text-center'>
          <ClockLoader color={'#090909'} loading={loading} size={42} />
          <h1 className='font-bold mt-4'>Status: {loadingmsg}</h1>
        </div>
      </div>
    );
  }


  return (
    <div class="flex flex-col items-center w-full">

      <div className="block lg:flex gap-4">
        <div className='flex-1 mb-4 lg:mb-0'>
          <InfoNota products={products} />
        </div>
        <div className='flex-1 bg-slate-100 shadow-md'>

          <div className='flex flex-col gap-4 p-8'>
            <h1 className="text-xl font-semibold mb-4">Dados do Produto</h1>
            <input
              placeholder="Código do Produto"
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={sku}
              onChange={(e) => setSku(e.target.value)}
              style={{ '::placeholder': { color: 'black' } }}
            ></input>
            <input
              placeholder="Quantidade"
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={qtd}
              type="number"
              onChange={(e) => setqtd(e.target.value)}
            ></input>
            <input
              placeholder="Valor de Venda"
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              value={valorVenda}
              type="number"
              onChange={(e) => setValorVenda(e.target.value)}
            ></input>

          </div>
          <div className="">
            <div className='flex flex-col p-8 rounded-md text-black'>
              <div className='flex flex-col  gap-2'>
                <label htmlFor="motivoSelect">Motivo da Venda</label>
                <select className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="motivoSelect" value={motivoSelecionado} onChange={handleMotivoChange}>
                  <option value="Se Arrependeu da Compra">Se arrependeu da compra</option>
                  <option value="Falta de Estoque">Falta de estoque</option>
                  <option value="Produto com Defeito">Produto com Defeito</option>
                  <option value="Falha na Entrega">Falha na Entrega</option>
                  <option value="Produto Extraviado">Extravio</option>
                  <option value="Produto com Defeito">Defeito</option>
                </select>
              </div>
              <div className=' flex flex-col rounded-md'>
                <label htmlFor="motivoSelect">Motivo do material</label>
                <select
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="motivoSelect" value={motivoProduto} onChange={handleMotivoProduto}>
                  <option value="Reembolsado">Reembolsado / Produto Devolvido</option>
                  <option value="Material Devolvido">Material Devolvido</option>

                  <option value="Coletar">Coletar</option>
                  <option value="Falha na Entrega">Falha na Entrega</option>
                  <option value="Descartar">Descartar</option>
                  <option value="Entregar">Entregar</option>
                  <option value="Estoque">Estoque</option>
                  <option value="Extravio">Extravio</option>
                  <option value="Full">Full</option>
                  <option value="Revisão">Revisão</option>
                  <option value="Transp vai reembolsar">Transp vai reembolsar</option>
                  <option value="Reembolso">Reembolso</option>
                  <option value="Sem Entrada">Sem Entrada</option>
                </select>
              </div>
            </div>
            {/* <textarea
          value={documentoReferenciadoText}
          onChange={(e) => setDocumentoReferenciadoText(e.target.value)}
          placeholder="Documento Referenciado"
          className="p-2 border border-gray-300 rounded-md focus:outline-none resize-none w-full mt-4"
          rows={5}
        ></textarea> */}
          </div>

        </div>
      </div>

      <button onClick={getProdutoNota}>
        pegar
      </button>

      <button
        className="appearance-none border rounded mt-4 py-2 px-3 bg-slate-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline shadow-md"
        onClick={updateDescricaoCurta}
      >
        Criar Entrada
      </button>
    </div>


  );
};

export default CriarDevolucao;
