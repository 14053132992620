import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../common/AuthContext';
import moment from 'moment';
import { DatePicker } from '../common/DataPicker';

const Pedidos = () => {
    const { accessCode, filtroDatas } = useAuth();
    const dataIni = moment(filtroDatas.startDate).format('YYYY-MM-DD').toString();
    const dataEnd = moment(filtroDatas.endDate).format('YYYY-MM-DD').toString();
    const [totalsByMarketplace, setTotalsByMarketplace] = useState([]);
    const [totalSales, setTotalSales] = useState(0);
    const [loading, setLoading] = useState(true);
    const accessToken = accessCode;
    const proxyUrl = 'https://thingproxy.freeboard.io/fetch/';
    const salesGoal = 4000000; // Meta de vendas, por exemplo, R$ 100.000,00

    function formatMoney(amount) {
        // Formata o número para conter duas casas decimais
        const formattedAmount = amount.toFixed(2);
        // Adiciona um separador de milhar
        const parts = formattedAmount.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        // Retorna o valor formatado com o símbolo de moeda
        return `R$${parts.join('.')}`;
    }

    const fetchProducts = async () => {
        setLoading(true);
        try {
            const responses = [];
            let page = 1;
            let newProducts;

            do {
                const response = await axios.get(`${proxyUrl}https://www.bling.com.br/Api/v3/pedidos/vendas?pagina=${page}&limite=100&dataInicial=${dataIni}&dataFinal=${dataEnd}`, {
                    headers: { 'Authorization': `Bearer ${accessToken}` },
                });

                newProducts = response.data.data;
                responses.push(...newProducts);
                calculateTotalsByMarketplace(responses); // Atualiza o estado após cada página de produtos ser carregada
                await new Promise(resolve => setTimeout(resolve, 350));
                page++;
            } while (newProducts.length >= 100);
        } catch (error) {
            console.error('Erro ao buscar produtos:', error);
            fetchProducts();
        } finally {
            setLoading(false);
        }
    };

    const calculateTotalsByMarketplace = (products) => {
        let newTotal = 0;
        const totals = products.reduce((acc, product) => {
            const marketplace = getIdLink(product.loja.id.toString());
            if (!acc[marketplace]) {
                acc[marketplace] = 0;
            }
            acc[marketplace] += product.total;
            newTotal += product.total; // Acumula o total de vendas
            return acc;
        }, {});

        setTotalSales(newTotal); // Atualiza o total de vendas
        const sortedTotals = Object.entries(totals).map(([marketplace, total]) => ({ marketplace, total }))
            .sort((a, b) => b.total - a.total);
        setTotalsByMarketplace(sortedTotals);
    };
    const getIdLink = (id) => {
        switch (id) {
            case '204432645': return 'Mercado Livre Full';
            case '204411234': return 'Shopee';
            case '203344818': return 'Magalu';
            case '204724248': return 'Amazon';
            case '204578069': return 'AliExpress';
            case '203147069': return 'Americanas';
            case '203208844': return 'Carrefour';
            case '203503415': return 'Casas Bahia';
            case '204512852': return 'Kabum';
            case '203309938': return 'Leroy Merlin';
            case '204624796': return 'Shein';
            case '203651867': return 'Tray';
            case '204965698': return 'Magalu 2';
            case '203146411': return 'Mercado Livre DBA';
            default: return 'Outra Loja';
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [filtroDatas]);

    // Cálculo de progresso
    const progress = Math.min((totalSales / salesGoal) * 100, 100);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
            <DatePicker />
            <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-6">
                <h2 className="text-2xl font-semibold text-gray-700 mb-4">Ranking de Vendas <br />{moment(dataIni).format(`DD/MM/YYYY`)} - {moment(dataEnd).format(`DD/MM/YYYY`)}</h2>
                <div className='bg-gray-100 p-4 rounded-md shadow-md mb-4'>
                    <h1>Meta</h1>
                    <div className="bg-gray-300 w-full h-5 rounded-full overflow-hidden">

                        <div className="bg-blue-600 h-5" style={{ width: `${progress}%` }}></div>
                    </div>
                    <p className="text-right mt-2">{formatMoney(totalSales)} / {formatMoney(salesGoal)} ({progress.toFixed(2)}%)</p>
                </div>
                
                <ul className="list-none w-full">
                    {totalsByMarketplace.map((item, index) => (
                        <li key={item.marketplace} className="flex shadow-md justify-between items-center bg-gray-50 px-4 py-2 border-b border-gray-200 last:border-b-0">
                            <span className="flex items-center text-lg font-medium text-gray-600">
                                <FontAwesomeIcon icon={faMedal} className={`mr-2 ${index < 3 ? 'text-orange-400' : (index === 3 || index === 4) ? 'text-gray-400' : 'text-amber-900'}`} />
                                {index + 1}. {item.marketplace}
                            </span>
                            <span className="text-lg font-semibold text-blue-600">{formatMoney(item.total)}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Pedidos;
