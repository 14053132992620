import React, { useState, useContext, useEffect } from 'react';
import { DadosContext } from '../../common/DadosContext';
import axios from 'axios';
import BlingAuthButton from './Connect';
import { useAuth } from '../../common/AuthContext';

const integracoesLogistica = [
  { nome: 'Jadlog', id: 'jadlog', requiresLogin: false },
  { nome: 'Jamef', id: 'jamef', requiresLogin: true },
  // Adicione mais integrações de logística conforme necessário
];

const integracoesMarketplaces = [
  { nome: 'Bling', id: 'bling', requiresLogin: true },
  { nome: 'Mercado Livre', id: 'mercado_livre', requiresLogin: true },
  { nome: 'Magalu', id: 'magalu', requiresLogin: true },
];

const IntegracaoForm = ({ titulo, dados }) => {
  const { accessCodeML, refreshCodeML, accessCodeMagalu, refreshCodeMagalu, accessCode, refreshCode } = useAuth();
  const { saveToken, tokens } = useContext(DadosContext);
  const [localTokens, setLocalTokens] = useState({});
  const [localRefreshTokens, setLocalRefreshTokens] = useState({});
  const [credentials, setCredentials] = useState({});
  const [notification, setNotification] = useState({ message: '', type: '' });
  const [isTokenSaved, setIsTokenSaved] = useState({});

  useEffect(() => {
    if (accessCode && refreshCode && !isTokenSaved['bling']) {
      setLocalTokens((prevTokens) => ({ ...prevTokens, bling: accessCode }));
      setLocalRefreshTokens((prevTokens) => ({ ...prevTokens, bling: refreshCode }));
      saveToken('bling', accessCode, refreshCode);
      setIsTokenSaved((prev) => ({ ...prev, bling: true }));
    }
  }, [saveToken, accessCode, refreshCode, isTokenSaved]);

  useEffect(() => {
    console.log('Magalu Access Token:', accessCodeMagalu);
    console.log('Magalu Refresh Token:', refreshCodeMagalu);
    if (accessCodeMagalu && refreshCodeMagalu && !isTokenSaved['magalu']) {
      setLocalTokens((prevTokens) => ({ ...prevTokens, magalu: accessCodeMagalu }));
      setLocalRefreshTokens((prevTokens) => ({ ...prevTokens, magalu: refreshCodeMagalu }));
      saveToken('magalu', accessCodeMagalu, refreshCodeMagalu);
      setIsTokenSaved((prev) => ({ ...prev, magalu: true }));
    }
  }, [accessCodeMagalu, refreshCodeMagalu, saveToken, isTokenSaved]);

  useEffect(() => {
    if (accessCodeML && refreshCodeML && !isTokenSaved['mercado_livre']) {
      setLocalTokens((prevTokens) => ({ ...prevTokens, mercado_livre: accessCodeML }));
      setLocalRefreshTokens((prevTokens) => ({ ...prevTokens, mercado_livre: refreshCodeML }));
      saveToken('mercado_livre', accessCodeML, refreshCodeML);
      setIsTokenSaved((prev) => ({ ...prev, mercado_livre: true }));
    }
  }, [accessCodeML, refreshCodeML, saveToken, isTokenSaved]);

  useEffect(() => {
    if (tokens) {
      const initialTokens = {};
      const initialRefreshTokens = {};
      tokens.forEach(token => {
        initialTokens[token.id] = token.token;
        initialRefreshTokens[token.id] = token.refreshToken;
      });
      setLocalTokens(initialTokens);
      setLocalRefreshTokens(initialRefreshTokens);
    }
  }, [tokens]);

  const handleInputChange = (id, value, type) => {
    if (type === 'token') {
      setLocalTokens((prevTokens) => ({ ...prevTokens, [id]: value }));
    } else if (type === 'refreshToken') {
      setLocalRefreshTokens((prevTokens) => ({ ...prevTokens, [id]: value }));
    } else {
      setCredentials((prevCredentials) => ({
        ...prevCredentials,
        [id]: { ...prevCredentials[id], [type]: value }
      }));
    }
  };

  const handleSaveToken = (id) => {
    const token = localTokens[id] || '';
    const refreshToken = localRefreshTokens[id] || '';
    if (saveToken) {
      saveToken(id, token, refreshToken);
      setNotification({ message: 'Token salvo com sucesso!', type: 'success' });
    } else {
      console.error('saveToken function is not defined');
      setNotification({ message: 'Erro ao salvar token.', type: 'error' });
    }
  };

  const handleLogin = async (id) => {
    const { username, password } = credentials[id];
    try {
      const response = await axios.post(`/${id}/login/`, { username, password });
      const token = response.data.token || response.token;
      const refreshToken = response.data.refreshToken || response.refreshToken;
      setLocalTokens((prevTokens) => ({ ...prevTokens, [id]: token }));
      setLocalRefreshTokens((prevTokens) => ({ ...prevTokens, [id]: refreshToken }));
      saveToken(id, token, refreshToken);
      setNotification({ message: 'Login bem-sucedido e token salvo!', type: 'success' });
    } catch (error) {
      console.error('Erro ao obter token:', error);
      setNotification({ message: 'Erro ao realizar login.', type: 'error' });
    }
  };

  return (
    <div className="bg-white shadow-md rounded p-4 mb-6">
      <h2 className="text-2xl font-bold mb-4">{titulo}</h2>
      <ul>
        {dados.map((integracao) => (
          <li key={integracao.id} className="mb-4">
            <h3 className="text-xl font-semibold">{integracao.nome}</h3>
            {(integracao.id === 'bling' || integracao.id === 'mercado_livre' || integracao.id === 'magalu') ? (
              <div className='bg-gray-200 p-4 rounded-xl'>
                <BlingAuthButton id={integracao.id} />
                {localTokens[integracao.id] && (
                  <div className="mt-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Access Token:
                    </label>
                    <input
                      type="text"
                      value={localTokens[integracao.id]}
                      readOnly
                      className="p-2 border border-gray-300 rounded-md w-full"
                    />
                  </div>
                )}
                {localRefreshTokens[integracao.id] && (
                  <div className="mt-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Refresh Token:
                    </label>
                    <input
                      type="text"
                      value={localRefreshTokens[integracao.id]}
                      readOnly
                      className="p-2 border border-gray-300 rounded-md w-full"
                    />
                  </div>
                )}
              </div>
            ) : integracao.requiresLogin ? (
              <div className='bg-gray-200 p-4 rounded-xl'>
                <input
                  type="text"
                  placeholder="Login"
                  value={credentials[integracao.id]?.username || ''}
                  onChange={(e) => handleInputChange(integracao.id, e.target.value, 'username')}
                  className="p-2 border border-gray-300 rounded-md w-full mb-2"
                />
                <input
                  type="password"
                  placeholder="Senha"
                  value={credentials[integracao.id]?.password || ''}
                  onChange={(e) => handleInputChange(integracao.id, e.target.value, 'password')}
                  className="p-2 border border-gray-300 rounded-md w-full mb-2"
                />
                <button
                  onClick={() => handleLogin(integracao.id)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Obter e Salvar Token
                </button>
                {localTokens[integracao.id] && (
                  <div>
                    <input
                      type="text"
                      placeholder="Token"
                      value={localTokens[integracao.id]}
                      readOnly
                      className="p-2 border border-gray-300 rounded-md w-full mt-2"
                    />
                  </div>
                )}
                {localRefreshTokens[integracao.id] && (
                  <div className="mt-2">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      Refresh Token:
                    </label>
                    <input
                      type="text"
                      placeholder="Refresh Token"
                      value={localRefreshTokens[integracao.id]}
                      readOnly
                      className="p-2 border border-gray-300 rounded-md w-full mt-2"
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className='bg-gray-200 p-4 rounded-xl'>
                <input
                  type="text"
                  placeholder="Insira o access token"
                  value={localTokens[integracao.id] || ''}
                  onChange={(e) => handleInputChange(integracao.id, e.target.value, 'token')}
                  className="p-2 border border-gray-300 rounded-md w-full mb-2"
                />
                <input
                  type="text"
                  placeholder="Insira o refresh token"
                  value={localRefreshTokens[integracao.id] || ''}
                  onChange={(e) => handleInputChange(integracao.id, e.target.value, 'refreshToken')}
                  className="p-2 border border-gray-300 rounded-md w-full mb-2"
                />
                <button
                  onClick={() => handleSaveToken(integracao.id)}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Salvar Token
                </button>
              </div>
            )}
          </li>
        ))}
      </ul>
      {notification.message && (
        <div className={`mt-4 p-2 rounded ${notification.type === 'success' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
          {notification.message}
        </div>
      )}
    </div>
  );
};

const Integracoes = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 min-h-screen py-32">
      <IntegracaoForm titulo="Integrações de Logística" dados={integracoesLogistica} />
      <IntegracaoForm titulo="Integrações de Marketplaces" dados={integracoesMarketplaces} />
    </div>
  );
};

export default Integracoes;
