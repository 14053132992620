import { Link } from 'react-router-dom';

export default function Logo() {
    return (
        <Link to="/" className="flex items-center flex-shrink-0 mb-2" aria-label="Nerp Technology">

            <span className="font-light text-gray-600 text-xl">Nerp <br /> <p className='text-xs bg-blue-600 shadow-sm rounded-sm p-1 text-center '><p className='rainbow rainbow_text_animated text-white'> Technoloy</p></p></span>

        </Link>
    )
}
