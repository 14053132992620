import React, { useState, useRef, useEffect, useContext } from 'react';
import { Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { AuthGoogleContext } from '../common/LoginContext';

export default function MobileMenu() {
  const { signed } = useContext(AuthGoogleContext);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showNotasMenu, setShowNotasMenu] = useState(false);
  const [showLogisticaMenu, setShowLogisticaMenu] = useState(false);
  const [showMercadoLivreMenu, setShowMercadoLivreMenu] = useState(false);
  const [showMagaluMenu, setShowMagaluMenu] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  const subMenuRef = useRef(null);
  const notasMenuRef = useRef(null);
  const logisticaMenuRef = useRef(null);
  const mercadoLivreMenuRef = useRef(null);
  const magaluMenuRef = useRef(null);

  // Close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // Close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="flex md:hidden">
      {/* Hamburger button */}
      <button
        ref={trigger}
        className={`hamburger ${mobileNavOpen ? 'active' : ''}`}
        aria-controls="mobile-nav"
        aria-expanded={mobileNavOpen}
        onClick={() => setMobileNavOpen(!mobileNavOpen)}
      >
        <span className="sr-only">Menu</span>
        <svg className="w-6 h-6 fill-current text-gray-900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <rect y="4" width="24" height="2" />
          <rect y="11" width="24" height="2" />
          <rect y="18" width="24" height="2" />
        </svg>
      </button>

      {/* Mobile navigation */}
      <div ref={mobileNav}>
        <Transition
          show={mobileNavOpen}
          as="nav"
          id="mobile-nav"
          className="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-hidden bg-white"
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 -translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ul className="px-5 py-2">
            {signed ? (
              <>
                <li
                  onClick={() => setShowNotasMenu(!showNotasMenu)}
                  className="relative"
                  ref={notasMenuRef}
                >
                  <Link to="#" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Notas</Link>
                  {showNotasMenu && (
                    <ul className="ml-4 mt-2">
                      <li>
                        <Link to="/nota/entrada" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Entrada</Link>
                      </li>
                      <li>
                        <Link to="/nota/ranking" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Análise</Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li
                  onClick={() => setShowSubMenu(!showSubMenu)}
                  className="relative"
                  ref={subMenuRef}
                >
                  <Link to="/integracao" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Integrações</Link>
                  {showSubMenu && (
                    <ul className="ml-4 mt-2">
                      <li>
                        <Link to="/integracao/option1" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Opção 1</Link>
                      </li>
                      <li>
                        <Link to="/integracao/option2" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Opção 2</Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li
                  onClick={() => setShowLogisticaMenu(!showLogisticaMenu)}
                  className="relative"
                  ref={logisticaMenuRef}
                >
                  <Link to="#" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Logística</Link>
                  {showLogisticaMenu && (
                    <ul className="ml-4 mt-2">
                      <li>
                        <Link to="/simulador" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Simulador JadLog</Link>
                      </li>
                      <li>
                        <Link to="/coleta" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Coleta</Link>
                      </li>
                      <li>
                        <Link to="/jadlog" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Lista Coleta</Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li
                  onClick={() => setShowMercadoLivreMenu(!showMercadoLivreMenu)}
                  className="relative"
                  ref={mercadoLivreMenuRef}
                >
                  <Link to="#" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Mercado Livre</Link>
                  {showMercadoLivreMenu && (
                    <ul className="ml-4 mt-2">
                      <li>
                        <Link to="/mercadolivre/concorrente" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Pedidos</Link>
                      </li>
                      <li>
                        <Link to="/mercadolivre/produtos" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Produtos</Link>
                      </li>
                      <li>
                        <Link to="/mercadolivre/mensagens" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Mensagens</Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li
                  onClick={() => setShowMagaluMenu(!showMagaluMenu)}
                  className="relative"
                  ref={magaluMenuRef}
                >
                  <Link to="#" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Magalu</Link>
                  {showMagaluMenu && (
                    <ul className="ml-4 mt-2">
                      <li>
                        <Link to="/magazineluiza/pedidos" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Pedidos</Link>
                      </li>
                      <li>
                        <Link to="/magalu/produtos" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Produtos</Link>
                      </li>
                      <li>
                        <Link to="/magalu/mensagens" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Mensagens</Link>
                      </li>
                    </ul>
                  )}
                </li>
              </>
            ) : (
              <li>
                <Link to="/login" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3 px-4 py-2 rounded-lg">
                  <span>Entrar</span>
                  <svg className="w-3 h-3 fill-current text-gray-400 shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                  </svg>
                </Link>
              </li>
            )}
          </ul>
        </Transition>
      </div>
    </div>
  );
}
