import { createContext, useState, useEffect } from 'react';
import { db } from "../components/Firebase";
import { collection, query, onSnapshot, where, addDoc, getDocs, updateDoc, doc } from 'firebase/firestore';
import moment from 'moment';
moment.locale("pt-br");

const DadosContext = createContext();

const DadosProvider = ({ children, user }) => {
  const [filtroDatas, setFiltroDatas] = useState({
    startDate: moment().startOf('year').toDate(),
    endDate: moment().endOf('month').toDate()
  });
  const [dataCarregada, setDataCarregada] = useState([]);
  const [notas, setNotas] = useState([]);
  const [notaIds, setNotaIds] = useState([]);
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    if (!user) return;

    const userLogado = user;

    const unsubscribeNotas = onSnapshot(collection(db, `users/${userLogado.uid}/notas`), (snapshot) => {
      const novasNotas = [];
      snapshot.forEach((doc) => {
        const notaData = { id: doc.id, ...doc.data() };
        if (!notaIds.includes(doc.id)) {
          novasNotas.push(notaData);
        }
      });
      setNotaIds(snapshot.docs.map(doc => doc.id));
      setNotas(prevNotas => [...prevNotas, ...novasNotas]);
    });

    const unsubscribeLogistica = onSnapshot(collection(db, `users/${userLogado.uid}/logistica/`), (snapshot) => {
      const novasNotas = [];
      snapshot.forEach((doc) => {
        const notaData = { id: doc.id, ...doc.data() };
        novasNotas.push(notaData);
      });
      setDataCarregada(novasNotas);
    });

    const fetchTokens = async () => {
      try {
        const q = query(collection(db, `users/${userLogado.uid}/integracoes`));
        const querySnapshot = await getDocs(q);
        const fetchedTokens = querySnapshot.docs.map(doc => ({ 
          id: doc.data().id, 
          token: doc.data().token, 
          refreshToken: doc.data().refreshToken // Adiciona refreshToken ao objeto
        }));
        console.log('Fetched tokens:', fetchedTokens); // Log the fetched tokens for debugging
        setTokens(fetchedTokens);
      } catch (error) {
        console.error('Erro ao buscar tokens: ', error);
      }
    };

    fetchTokens();

    return () => {
      unsubscribeNotas();
      unsubscribeLogistica();
    };
  }, [user]);

  const saveToken = async (id, token, refreshToken) => {
    if (!user) return;

    const userLogado = user;

    try {
      const q = query(collection(db, `users/${userLogado.uid}/integracoes`), where('id', '==', id));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref;
        await updateDoc(docRef, { token, refreshToken });
      } else {
        await addDoc(collection(db, `users/${userLogado.uid}/integracoes`), { id, token, refreshToken });
      }

      setTokens((prevTokens) => {
        const existingTokenIndex = prevTokens.findIndex(t => t.id === id);
        if (existingTokenIndex !== -1) {
          const updatedTokens = [...prevTokens];
          updatedTokens[existingTokenIndex] = { id, token, refreshToken };
          return updatedTokens;
        }
        return [...prevTokens, { id, token, refreshToken }];
      });
    } catch (error) {
      console.error('Erro ao salvar token: ', error);
    }
  };


  const setFilterDates = (option) => {
    const now = moment();
    let startDate, endDate;

    if (option === '7d') {
      startDate = now.clone().subtract(7, 'days').startOf('day').toDate();
      endDate = now.clone().endOf('day').toDate();
    } else if (option === '1m') {
      startDate = now.clone().subtract(1, 'month').startOf('month').toDate();
      endDate = now.clone().endOf('month').toDate();
    } else if (option === '1y') {
      startDate = now.clone().subtract(1, 'year').startOf('year').toDate();
      endDate = now.clone().endOf('year').toDate();
    } else {
      return;
    }

    setFiltroDatas({ startDate, endDate });
  };

  const updateFiltroDatas = (startDate, endDate) => {
    setFiltroDatas({
      startDate: moment(startDate, 'YYYY-MM-DD').startOf('day').toDate(),
      endDate: moment(endDate, 'YYYY-MM-DD').endOf('day').toDate(),
    });
  };

  const createNota = async (ganho) => {
    if (!user) return;

    const userLogado = user;
    const gastoObj = {
      nota: ganho,
    };

    try {
      await addDoc(collection(db, `users/${userLogado.uid}/notas`), gastoObj);
    } catch (error) {
      console.error("Erro ao adicionar gasto: ", error);
    }
  };

  const createLogJadlog = async (logistica) => {
    if (!user) return;

    const userLogado = user;
    const q = query(collection(db, `users/${userLogado.uid}/logistica/`), where("jadlog.shipmentId", "==", logistica.shipmentId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      throw new Error('Shipment ID já cadastrado');
    }

    const logObj = { jadlog: logistica };

    try {
      await addDoc(collection(db, `users/${userLogado.uid}/logistica/`), logObj);
    } catch (error) {
      console.error("Erro ao adicionar logistica: ", error);
    }
  };

  const updateLogJadlog = async (id, updatedData) => {
    if (!user) return;

    const userLogado = user;
    try {
      const logRef = doc(db, `users/${userLogado.uid}/logistica/`, id);
      await updateDoc(logRef, updatedData);
    } catch (error) {
      console.error("Erro ao atualizar logistica: ", error);
    }
  };

  return (
    <DadosContext.Provider
      value={{
        notas,
        updateFiltroDatas,
        filtroDatas,
        createNota,
        createLogJadlog,
        updateLogJadlog,
        dataCarregada,
        setFilterDates,
        setDataCarregada,
        tokens,
        saveToken,
      }}
    >
      {children}
    </DadosContext.Provider>
  );
};

export { DadosContext, DadosProvider };
